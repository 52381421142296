import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import AccountDeletionRequest from 'views/pages/AccountDeletion';

// login option 3 routing
const PrivacyPolicy = Loadable(lazy(() => import('views/pages/PrivacyPolicy')));

// ==============================|| NON-AUTHENTICATION ROUTING ||============================== //

const NonLayoutRoutes = () => {
  return {
    path: '/',
    element: <MinimalLayout />,
    children: [
      {
        path: '/privacy-policy',
        children: [
          {
            path: '',
            element: <PrivacyPolicy />,
          },
        ],
      },
      {
        path: '/account-deletion',
        children: [
          {
            path: '',
            element: <AccountDeletionRequest />,
          },
        ],
      },
    ],
  };
};

export default NonLayoutRoutes;
