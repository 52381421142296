import React from 'react';
import { calculateProductPrices } from './Carts';
import { CONST_PAGE_CART } from 'utils/constants';
import { formatCurrency } from 'utils/commonFunc';
import CustomTwoInlineColumns from 'ui-component/CustomTwoInlineColumns/CustomTwoInlineColumns';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SpecialRequest from './SpecialRequest';
import useAppContext from 'store/useAppContext';
import { voucherDiscountsForSelect } from 'utils/variables';

const calculateCoupenDiscount = ({voucherDiscountType, voucherDiscount, totalAmount}) => {
  if(voucherDiscount) {
    const couponDis = voucherDiscountType === voucherDiscountsForSelect[1].value ? voucherDiscount : (totalAmount * (voucherDiscount/100));
    return couponDis || 0;
  }
}

const PriceDetailsCard = ({ viewPage, carts }) => {
   const {
    vouchersState: { voucherDiscountType = '',  voucherDiscount = 0, },
  ordersState: { orderFeeFilterItem = {}, serviceFeeFilterItem = {}, deliveryFeeFilterItem = {} },
  } = useAppContext();
  const smallOrderFee = orderFeeFilterItem?.flatAmount || 0;
  const deliveryFee = deliveryFeeFilterItem?.flatAmount || 0;
  const serviceFee = serviceFeeFilterItem?.flatAmount || 0;
  const addOnFee = deliveryFee + smallOrderFee + serviceFee;
  const totalPrices = calculateProductPrices(carts);
  const finalSmallOrderFee = smallOrderFee
  const isPageCart = viewPage === CONST_PAGE_CART;
  const itemLen = carts?.length;
  const couponDiscount = calculateCoupenDiscount({
    voucherDiscountType,
    voucherDiscount,
    totalAmount: isPageCart ? totalPrices.totalPriceWithDiscount: totalPrices.totalPriceToShow
  }) || 0;

  // totalPrices.totalPriceWithDiscount=totalPrices.totalPriceWithDiscount - couponDiscount
  totalPrices.totalPriceToShow=totalPrices.totalPriceToShow-couponDiscount

  const priceDetailsDiv = [
    {
      label: `Price (${itemLen || 1} ${itemLen?.length > 1 ? 'items' : 'item'})`,
      value: formatCurrency(
        // isPageCart ? 
        totalPrices.totalPriceWithDiscount
       // : totalPrices.totalPriceToShow
      ),
    },
    ...(couponDiscount
      ? [
          {
            label: 'Coupon Applied',
            value: <span style={{color:'green'}}>- {formatCurrency(couponDiscount)}</span>,
          },
        ]
      : []),
    ...(totalPrices?.totalDiscountedAmount
      ? [
          {
            label: 'Order Discount',
            value: <span style={{color:'green'}}>- {formatCurrency(totalPrices.totalDiscountedAmount)}</span>,
          },
        ]
      : []),
    ...(finalSmallOrderFee ? [
      { label: <Box>
      <Box>
        Small order free
        <Box sx={{fontSize: 10, ml:1}}>
        <CustomTypography variant="caption" fontSize="extrasmall">
          {orderFeeFilterItem?.description || 'Add more items to your cart to remove this fee'} 
        </CustomTypography>
        </Box>
      </Box>
    </Box>, value: formatCurrency(smallOrderFee) }]: []),
    { label: 'Delivery fee', value: formatCurrency(deliveryFee) },
    { label: 'Service fee', value: formatCurrency(serviceFee) },
  ].map(({ label, value }, i) => (
    <CustomTwoInlineColumns key={label} sx={{ paddingRight: 3, paddingY: 0.5 }}>
      <>{label}</>
      <CustomTypography variant="h5">{value}</CustomTypography>
    </CustomTwoInlineColumns>
  ));

  return (
    <Box>
      <Paper sx={{ minHeight: 150 }}>
        {/* Add on section for checkout */}
        {isPageCart && <SpecialRequest />}
        {/* Price details section */}
        <Box>
        <CustomTypography
        variant="h4"
          sx={{
            padding: 2,
            borderTop: 1,
            borderColor: 'divider',
            fontWeight: 'bold',
          }}>
          PRICE SUMMARY
        </CustomTypography>
        <Box sx={{ paddingTop: 1, paddingLeft: 2, paddingBottom: 2, borderBottom: 1, borderColor: 'divider' }}>
          {priceDetailsDiv}
        </Box>
        <Box sx={{ paddingLeft: 2, paddingBottom: 2, borderBottom: 1, borderColor: 'divider' }}>
          <CustomTwoInlineColumns sx={{ paddingRight: 3, paddingTop: 2 }}>
            <b>{isPageCart ? <>Total Amount</> : <>Total Payable</>}</b>
            <CustomTypography variant="h5">{formatCurrency(totalPrices.totalPriceToShow+addOnFee)}</CustomTypography>
          </CustomTwoInlineColumns>
        </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default PriceDetailsCard;