import { setContextState } from 'utils/contextStoreUtils/setContextUtils';
import { CONST_FIELD_REQUIRED, CONST_MSG_INVALID_EMAIL, validateEmail } from './commonUtils';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import { CONST_TYPE_EMAIL } from '../constants';

const checkNumberMinAndMax = (field, { minNum = null, maxNum = null }) => {
  const { fieldValue = '', fieldLabel = '' } = field ?? {};
  if (minNum && maxNum && fieldValue && (fieldValue < minNum || fieldValue > maxNum)) {
    return `${fieldLabel} should be between ${minNum} and ${maxNum}`;
  } else if (!maxNum && minNum && fieldValue && fieldValue < minNum) {
    return `${fieldLabel} should not be less than ${minNum}`;
  } else if (!minNum && maxNum && fieldValue && fieldValue > maxNum) {
    return `${fieldLabel} should not be greater than ${maxNum}`;
  }
  return '';
};

const useValidateForm = () => {
  const { getMethodByModule, getStateParamDataByModule } = useStoreAccessByModule();

  const validateField = field => {
    let validationError = '';

    // Basic required field validation
    if (field?.options?.isReq) {
      if (!field.fieldValue || field.fieldValue.toString().trim() === '') {
        return `${field.fieldLabel} ${CONST_FIELD_REQUIRED}`;
      }
    }

    // If field has value, proceed with other validations
    if (field.fieldValue) {
      if (field.type === CONST_TYPE_EMAIL || field.fieldName?.includes(CONST_TYPE_EMAIL)) {
        validationError = validateEmail(field.fieldValue) ? '' : CONST_MSG_INVALID_EMAIL;
      } else if (field.type === 'number') {
        // Validate number format
        const numValue = field.fieldValue.toString().replace(/[^0-9]/g, '');
        if (!numValue) {
          validationError = `${field.fieldLabel} must be a valid number`;
        }
        // Check min/max if specified
        else if (field.options?.minNum || field.options?.maxNum) {
          validationError = checkNumberMinAndMax(field, field.options);
        }
      }
    }

    return validationError;
  };

  const validateForm = module => {
    const formState = getStateParamDataByModule({
      module,
      passStateParamName: 'formState',
    });

    let isErrorExist = false;
    const stateObjList = Object.values(formState).map(field => {
      const validationError = validateField(field);
      if (!isErrorExist && validationError) {
        isErrorExist = true;
      }

      return {
        ...field,
        options: {
          ...field?.options,
          validationError,
        },
      };
    });

    const updatedState = stateObjList.reduce((acc, field) => {
      acc[field?.fieldName] = field;
      return acc;
    }, {});

    setContextState({
      setState: getMethodByModule({ module }),
      paramName: 'formState',
      paramValue: updatedState,
    });

    return { isErrorExist, formState };
  };

  return { validateForm };
};

export default useValidateForm;
