import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Alert,
  Box,
  Stack,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { getLocalStorage, setLocalStorage } from 'utils/commonFunc';
import { handleApiAction } from 'utils/apiUtils/apiAction';
import useAppContext from 'store/useAppContext';
import { CONST_LOGGED_USER, CONST_POST, CONST_ROUTE_SIGN_IN } from 'utils/constants';
import CustomLoader from 'ui-component/CustomLoader/CustomLoader';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { initialLoggedUser } from 'store/providers/states/authState';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';

const AccountDeletionRequest = () => {
    const navigate = useNavigate();
  const isAccountDeleted = getLocalStorage('isAccountDeleted') === 'true';
  const { crudMethods, authState: { loggedUser }, authMethods: { setAuthState } } = useAppContext();
  const [formData, setFormData] = useState({
    email: loggedUser.userEmail,
    reason: '',
    understanding: false,
    confirmation: false,
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(!!isAccountDeleted);
  const [isLoading, setLoading]= useState(false)

  useEffect(() => {
    setFormData(prevState => ({ ...prevState, email: loggedUser.userEmail }))
  }, [loggedUser])

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email) {
      newErrors.email = 'Email is required';
    }

    if (!formData.reason.trim()) {
      newErrors.reason = 'Please provide a reason for deletion';
    }

    if (!formData.understanding) {
      newErrors.understanding = 'You must acknowledge this understanding';
    }

    if (!formData.confirmation) {
      newErrors.confirmation = 'You must confirm this action';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
          await handleApiAction({
            crudMethods,
            module: 'COMMON',
            action: CONST_POST,
            apiPropName: 'userAccountDelete',
            payload: formData,
          });
          setSubmitted(true);
          setLocalStorage('isAccountDeleted', 'true');
      } catch(err) {

      } finally {
        setLoading(false);
      }
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = e => {
    const { name, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleGoBack = () => {
    window.history.back()
  }

  const handleClose = () => {
    setContextState({
      setState: setAuthState,
      paramName: CONST_LOGGED_USER,
      paramValue: initialLoggedUser,
    });
    localStorage.clear();
    navigate(CONST_ROUTE_SIGN_IN);
  }

  if (submitted) {
    return (
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Request Submitted
          </Typography>
          <Typography color="text.secondary">
            Your account deletion request has been received. You will receive a confirmation email shortly.
          </Typography>
        </Paper>
        <Box textAlign="center">
        <Button type='button' sx={{m:2}} variant='outlined' color='error' onClick={handleClose}>Close</Button>
        </Box>
      </Container>
    );
  }

  return (
    <>
    <Button type='button' sx={{m:2}} startIcon={<ArrowBack />} variant='outlined' onClick={handleGoBack}>Go back</Button>
    <Container maxWidth="sm" sx={{mb:3, mt:0}}>
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Account Deletion Request
        </Typography>
        <Typography color="text.secondary" paragraph>
          Please fill out this form to request the deletion of your account. This action cannot be undone.
        </Typography>

        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Alert severity="warning" icon={<WarningIcon />} sx={{ mb: 2, border: '1px solid orange' }}>
              Warning: Account deletion is permanent and cannot be reversed. All your data will be permanently removed.
            </Alert>

            <TextField
              fullWidth
              label="Email Address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
              required
              disabled={loggedUser.userEmail}
            />

            <TextField
              fullWidth
              label="Reason for Deletion"
              name="reason"
              multiline
              rows={4}
              value={formData.reason}
              onChange={handleInputChange}
              error={!!errors.reason}
              helperText={errors.reason}
              required
              placeholder="Please tell us why you want to delete your account..."
            />

            <FormControlLabel
              control={
                <Checkbox name="understanding" checked={formData.understanding} onChange={handleCheckboxChange} />
              }
              label={
                <Typography variant="body2" color="text.secondary">
                  I understand that this action is permanent and cannot be undone. All my data, including account
                  history and preferences, will be permanently deleted.
                </Typography>
              }
            />
            {errors.understanding && (
              <Typography color="error" variant="caption">
                {errors.understanding}
              </Typography>
            )}

            <FormControlLabel
              control={<Checkbox name="confirmation" checked={formData.confirmation} onChange={handleCheckboxChange} />}
              label={
                <Typography variant="body2" color="text.secondary">
                  I confirm that I want to permanently delete my account and all associated data.
                </Typography>
              }
            />
            {errors.confirmation && (
              <Typography color="error" variant="caption">
                {errors.confirmation}
              </Typography>
            )}

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
              <Button type='button' variant="outlined" onClick={handleGoBack} disabled={isLoading}>
                Cancel
              </Button>
              <Button disabled={isLoading} type="submit" variant="contained" color="error" startIcon={isLoading && <CustomLoader size={16} />}>
                Request Deletion
              </Button>
            </Box>
          </Stack>
        </form>
      </Paper>
    </Container>
    </>
  );
};

export default AccountDeletionRequest;
