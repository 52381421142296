import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ mt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function CustomTabs({ module, tabName, tabsList, isActionPending = false }) {
  const { getMethodByModule, getStateParamDataByModule } = useStoreAccessByModule();
  const setState = getMethodByModule({ module });
  const activeTabValue = getStateParamDataByModule({ module, passStateParamName: tabName || 'activeTab' });

  const getTabIndex = tabValue => {
    const index = tabsList.findIndex(tab => tab.value === tabValue);
    return index >= 0 ? index : 0;
  };

  const tabValue = getTabIndex(activeTabValue);

  const handleChange = (event, newIndex) => {
    if (!isActionPending) {
      setContextState?.({
        setState,
        paramName: tabName || 'activeTab',
        paramValue: tabsList[newIndex].value,
      });
    }
  };

  useEffect(() => {
    if (!activeTabValue && !isActionPending) {
      setContextState?.({
        setState,
        paramName: tabName || 'activeTab',
        paramValue: tabsList[0].value,
      });
    }
  }, []);

  return (
    <>
      <Paper
        sx={{
          borderRadius: 1,
        }}
        elevation={1}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="full width tabs example"
          variant="fullWidth"
          scrollButtons="auto"
          allowScrollButtonsMobile
          textColor="inherit">
          {tabsList.map((tab, i) => {
            return <Tab key={i} label={tab.label} {...a11yProps(i)} />;
          })}
        </Tabs>
      </Paper>
      <Box>
        {tabsList.map((tab, i) => {
          return (
            <TabPanel key={i} value={tabValue} index={i}>
              {tab?.element || `No ${tab.label} Found.`}
            </TabPanel>
          );
        })}
      </Box>
    </>
  );
}
