import React from 'react';
import Box from '@mui/material/Box';
import CustomResGrid from 'ui-component/CustomResponsiveGrid/CustomResGrid';
import { CustomProductImage, CustomProductPrice } from '../view-product/ViewProduct';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CustomIcon from 'ui-component/CustomIcon/CustomIcon';
import { CONST_DELETE, CONST_LABEL_REMOVE, CONST_MODULE_CARTS } from 'utils/constants';
import { useNavigate } from 'react-router-dom';

const module = CONST_MODULE_CARTS;
const CartCard = ({ cartItem: product, onQuantityChange, putting, deleteItem, deleting }) => {
  const navigate = useNavigate();
  return (
    <Paper sx={{ py: 2 }}>
      <CustomResGrid>
        {/***** Product Image Section *******/}
        <Box columnsizes={{ xs: 6, md: 3 }}>
          <CustomProductImage style={{ maxWidth: '55%', maxHeight: '55%', borderRadius: 20 }} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginTop: 2,
            }}>
            <CustomIcon
              module={module}
              isActionPostOrPut={true}
              onClick={() => {
                if (product?.quantity > 1) {
                  onQuantityChange({
                    ...product,
                    quantity: product?.quantity - 1,
                  });
                }
              }}>
              <RemoveCircleOutlineIcon />
            </CustomIcon>
            <Chip label={product.quantity} variant="outlined" sx={{ paddingX: 2 }} />
            <CustomIcon
              module={module}
              isActionPostOrPut={true}
              onClick={() => {
                onQuantityChange({
                  ...product,
                  quantity: product?.quantity + 1,
                });
              }}>
              <AddCircleOutlineIcon />
            </CustomIcon>
          </Box>
        </Box>
        {/***** Name and Price Section *******/}
        <Box columnsizes={{ xs: 6, md: 7 }}>
          <CustomTypography
            variant="h3"
            gutterBottom
            sx={{ marginBottom: 1, cursor: 'pointer' }}
            onClick={() => {
              product?.productId && navigate(`/products/view-product/${product?.productId}`);
            }}>
            {product.productName}
          </CustomTypography>
          <CustomTypography variant="body" gutterBottom label={product.description} />
          {/* Price section */}
          <CustomProductPrice product={product} />
          <CustomButton
            sx={{ marginTop: 2 }}
            size="small"
            startIcon={<DeleteIcon />}
            variant="outlined"
            color="error"
            name={CONST_LABEL_REMOVE}
            action={CONST_DELETE}
            deleteId={product?.cartItemId}
            deleteItem={deleteItem}
            deleting={deleting}
            disabled={putting || deleting}
            passLoading={deleting}
            showLoader
          />
        </Box>
        {/***** Delivery Details Section *******/}
        {/* <Box columnsizes={{ xs: 6, md: 2 }}>
          <Box sx={{ paddingX: 2 }}>
            <CustomTypography variant="caption">
              <b>Delivery:</b> Will arrive soon
            </CustomTypography>
          </Box>
        </Box> */}
      </CustomResGrid>
    </Paper>
  );
};

export default CartCard;
