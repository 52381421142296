import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import CustomTable from 'ui-component/tables/CustomTable';
import CustomRightButton from 'ui-component/CustomRightButton';
import useAppContext from 'store/useAppContext';
import { handleApiAction } from 'utils/apiUtils/apiAction';
import { CONST_DELETE, CONST_MODULE_ORDERS, CONST_TYPE_SUCCESS } from 'utils/constants';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import { formatCurrency } from 'utils/commonFunc';

const module = CONST_MODULE_ORDERS;
const OrderFees = ({ config, children, isSaveFormVisible, toggleSaveFormVisible, handleFetchFeeList }) => {
  const {
    crudMethods,
    ordersState: { feeActiveTab, orderFeeData, serviceFeeData, deliveryFeeData },
  } = useAppContext();
  const { getMethodByModule } = useStoreAccessByModule();
  const setState = getMethodByModule({ module });

  const commonParams = useMemo(
    () => ({
      crudMethods,
      setState,
      module,
    }),
    [crudMethods, setState]
  );

  const idName = useMemo(() => {
    if (feeActiveTab === 'ORDER_FEE') {
      return 'smallOrderFeeId';
    } else if (feeActiveTab === 'SERVICE_FEE') {
      return 'serviceFeeId';
    } else if (feeActiveTab === 'DELIVERY_FEE') {
      return 'deliveryFeeId';
    }
  }, [feeActiveTab]);

  const data = useMemo(() => {
    if (feeActiveTab === 'ORDER_FEE') {
      return orderFeeData;
    } else if (feeActiveTab === 'SERVICE_FEE') {
      return serviceFeeData;
    } else if (feeActiveTab === 'DELIVERY_FEE') {
      return deliveryFeeData;
    }
  }, [feeActiveTab, orderFeeData, serviceFeeData, deliveryFeeData]);

  const handleDelete = async delId => {
    let apiPropName = '';
    if (feeActiveTab === 'ORDER_FEE') {
      apiPropName = 'deleteSmallOrderFees';
    } else if (feeActiveTab === 'SERVICE_FEE') {
      apiPropName = 'deleteServiceFees';
    } else if (feeActiveTab === 'DELIVERY_FEE') {
      apiPropName = 'deleteDeliveryFees';
    }
    await handleApiAction({
      ...commonParams,
      apiPropName,
      action: CONST_DELETE,
      idName,
      delId,
      notification: { show: true, type: CONST_TYPE_SUCCESS },
    });
    handleFetchFeeList();
  };

  return (
    <Box>
      {!isSaveFormVisible && <CustomRightButton onClick={toggleSaveFormVisible} />}
      <Box sx={{ my: 1 }}>{children}</Box>
      <CustomTable
        module={CONST_MODULE_ORDERS}
        dataList={(data || [])?.map(feeItem => ({
          ...feeItem,
          percentage: `${feeItem?.percentage}%`,
          flatAmount: formatCurrency(feeItem?.flatAmount),
          maxOrderAmount: formatCurrency(feeItem?.maxOrderAmount),
        }))}
        dataKeys={config.tableKeys}
        headers={config.tableHeaders}
        idName={idName}
        deleteItem={handleDelete}
        hideEdit={true}
      />
    </Box>
  );
};

export default OrderFees;
