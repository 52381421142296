import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import CustomFlexRow from 'ui-component/CustomFlexRow/CustomFlexRow';
import { CONST_GET, CONST_LOCAL_STORAGE_USER_ADDRESS, CONST_MODULE_ADDRESSES, CONST_PUT } from 'utils/constants';
import { getLocalStorage, getLocalUser, setLocalStorage } from 'utils/commonFunc';
import { Delete } from '@mui/icons-material';
import CustomTooltip from 'ui-component/CustomTooltip/CustomTooltip';
import { handleApiAction } from 'utils/apiUtils/apiAction';
import useAppContext from 'store/useAppContext';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import CustomLoader from 'ui-component/CustomLoader/CustomLoader';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import { IconButton } from '@mui/material';

const borderStyle = {
  border: `3px solid #6bd098`,
};

const module = CONST_MODULE_ADDRESSES;
export const getAndStorePrimaryAddress = async ({ crudMethods, setState, userName, addressId = 0 }) => {
  const res = await handleApiAction({
    module,
    crudMethods,
    setState,
    action: CONST_GET,
    apiPropName: 'getPrimaryAddress',
    loadingParam: 'getPrimaryAddressFetching',
    userName,
  });
  if (res.ok && res?.data?.pinCode) {
    setLocalStorage(CONST_LOCAL_STORAGE_USER_ADDRESS, { ...res?.data, ...(addressId && { addressId }) });
  }
};

const AddressInfo = ({ addressInfo, idName, deleteItem, deletingAddressId, setDeleting, setToggleAddress }) => {
  const { crudMethods } = useAppContext();
  const { getMethodByModule } = useStoreAccessByModule();
  const { aliasName = '', addressLine = '', addressStreet, district, state, pinCode, phoneNumber } = addressInfo ?? {};
  const [primarySettingLoading, setPrimaryAddressLoading] = useState(false);

  const user = getLocalUser();
  const handleSetPrimaryAddress = () => {
    return handleApiAction({
      module,
      crudMethods,
      setState: getMethodByModule({ module }),
      action: CONST_PUT,
      apiPropName: 'updatePrimaryAddress',
      loadingParam: 'primaryAddressUpdating',
      userName: user?.userId,
      payload: addressInfo,
    });
  };

  const setAsPrimary = async () => {
    if (!deletingAddressId) {
      setPrimaryAddressLoading(true);
      await handleSetPrimaryAddress();
      setPrimaryAddressLoading(false);
      await getAndStorePrimaryAddress({
        crudMethods,
        setState: getMethodByModule({ module }),
        userName: user?.userId,
        addressId: addressInfo?.addressId,
      });
      setToggleAddress(prevState => !prevState);
    }
  };

  const deleteItemHandler = async delId => {
    setDeleting(delId);
    await deleteItem(delId);
    setDeleting(null);
  };

  const existAddress = getLocalStorage(CONST_LOCAL_STORAGE_USER_ADDRESS) ?? {};

  const isExist = existAddress?.addressId === addressInfo?.addressId;

  return (
    <Paper sx={{ mb: 2, p: 2, ...(isExist ? borderStyle : {}) }} variant="outlined">
      {/* {isExist && <BadgeBox badgeLabel="Primary" sx={{ mr: -2 }} />} */}
      <CustomFlexRow sx={{ mt: 1, justifyContent: 'space-between', alignItems: 'center' }}>
        {/* <Box>
          <CustomTooltip content={true ? 'Primary Address' : 'Set as Primary Address'}>
            <CustomRadio />
          </CustomTooltip>
        </Box> */}
        <Box>
          <>
            <CustomTypography>
              <b>{[aliasName, addressLine].join(', ')}</b>
            </CustomTypography>
            <CustomTypography sx={{ paddingY: 1 }}>
              {addressStreet} {[district, state].join(', ')} - {pinCode}
            </CustomTypography>
            <CustomTypography>
              <b>Phone:</b> {phoneNumber}
            </CustomTypography>
          </>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
          {!isExist ? (
            <CustomTooltip content={!isExist ? 'Set as default address' : ''}>
              <CustomButton
                name="Mark as Default"
                size="small"
                sx={{
                  cursor: 'pointer',
                  color: isExist ? '#6bd098' : 'default',
                }}
                onClick={() => !isExist && setAsPrimary()}
                disabled={primarySettingLoading || deletingAddressId}
                variant="outlined"
                passLoading={primarySettingLoading}
                showLoader
              />
            </CustomTooltip>
          ) : (
            <Box />
          )}
          {deletingAddressId === addressInfo?.[idName] ? (
            <CustomLoader size={20} color="error" />
          ) : (
            <IconButton
              onClick={() => !deletingAddressId && deleteItemHandler?.(addressInfo?.[idName])}
              disabled={deletingAddressId === addressInfo?.[idName]}>
              <Delete
                sx={{
                  cursor: 'pointer',
                  color: 'error.main',
                }}
              />
            </IconButton>
          )}
        </Box>
        {/* <CustomDropdownMenu
          module={module}
          deleteItem={() => deleteItem?.(addressInfo?.[idName])}
          addonList={
            !isExist && [
              {
                action: CONST_PUT,
                icon: <CheckBox sx={{ pr: 1 }} />,
                label: 'Set as Primary',
                handleMenuClick: () => {
                  setAsPrimary();
                },
                isLoading: false,
              },
            ]
          }
        /> */}
      </CustomFlexRow>
    </Paper>
  );
};

export default AddressInfo;
