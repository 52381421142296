import React, { useEffect, useImperativeHandle } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomCard from 'ui-component/CustomCard/CustomCard';
import CustomRowColumns from 'ui-component/CustomRowColumns/CustomRowColumns';
import CustomInput from 'ui-component/forms/CustomInput';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import useAppContext from 'store/useAppContext';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const FeeForm = ({ feeFormRef, config, onSubmit, onCancel, initialData, feeInfoSaving = false }) => {
   const {
    ordersState: { feeActiveTab },
  } = useAppContext();
  const classes = useStyles();
  const formInitialData = initialData || { 
    maxOrderAmount: null, 
    flatAmount: null, 
    percentage: null,
    lastUpdatedField: null,
    maxDistanceKm: null
  }
  const [formData, setFormData] = React.useState(formInitialData);
  
  const { maxOrderAmount, flatAmount, percentage, lastUpdatedField } = formData || {};

  useEffect(() => {
    if ((!maxOrderAmount || maxOrderAmount <= 0) && feeActiveTab !== 'DELIVERY_FEE') return;
    if (lastUpdatedField === 'flatAmount' && flatAmount !== null && feeActiveTab !== 'DELIVERY_FEE') {
      const calculatedPercentage = Number(((flatAmount / maxOrderAmount) * 100).toFixed(2));
      setFormData(prev => ({
        ...prev,
        percentage: calculatedPercentage,
      }));
    } else if (lastUpdatedField === 'percentage' && percentage !== null && feeActiveTab !== 'DELIVERY_FEE') {
      const calculatedFlatAmount = Number(((percentage / 100) * maxOrderAmount).toFixed(2));
      setFormData(prev => ({
        ...prev,
        flatAmount: calculatedFlatAmount,
      }));
    }
  }, [flatAmount, percentage, maxOrderAmount, lastUpdatedField, feeActiveTab]);

  const resetForm = () => {
    setFormData(formInitialData)
  }

  useImperativeHandle(feeFormRef, () => ({ resetForm }));

  const handleSubmit = (e) => {
    e.preventDefault();
    const { lastUpdatedField, ...submitData } = formData;
    onSubmit(submitData);
  };

  const handleChange = (value, name) => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
      lastUpdatedField: name
    }));
    if (name === 'maxOrderAmount') {
      setFormData(prev => ({
        ...prev,
        flatAmount: null,
        percentage: null,
        [name]: value,
        lastUpdatedField: name
      }));
    }
  };

  return (
    <CustomRowColumns
      listToLoop={[
        { md: 6 },
        {
          element: (
            <CustomCard>
              <form onSubmit={handleSubmit} className={classes.form}>
                {config.fields.map((field) => (
                  <CustomInput
                    key={field.name}
                    fieldName={field.name}
                    label={field.label}
                    type={field.type}
                    fieldValue={formData[field.name] || ''}
                    onChange={handleChange}
                    required
                    fullWidth
                    disabled={
                      ['flatAmount', 'percentage'].includes(field.name) && 
                      ((feeActiveTab !== 'DELIVERY_FEE' && (!formData?.maxOrderAmount || formData.maxOrderAmount <= 0)) ||
                      (feeActiveTab === 'DELIVERY_FEE' && (!formData?.maxDistanceKm || formData.maxDistanceKm <= 0)))
                    }
                  />
                ))}
                <Box className={classes.buttons}>
                  <CustomButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="Save"
                    passLoading={feeInfoSaving}
                  />
                  <CustomButton
                    onClick={onCancel}
                    name="Cancel"
                    disabled={feeInfoSaving}
                  />
                </Box>
              </form>
            </CustomCard>
          ),
          md: 6,
        },
      ]}
    />
  );
};

export default FeeForm;