import React, { useState, useEffect, useMemo } from 'react';
import {
  CONST_ACTION_ADD,
  CONST_MODULE_ADDRESSES,
  CONST_DELETE,
  CONST_GET,
  CONST_GETALL,
  CONST_POST,
  CONST_PUT,
} from 'utils/constants';
import { getLocalUser } from 'utils/commonFunc';
import Box from '@mui/material/Box';
import useAppContext from 'store/useAppContext';
// Pagination
import { handleApiAction } from 'utils/apiUtils/apiAction';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import CustomRightButton from 'ui-component/CustomRightButton';
import CustomNoDataFound from 'ui-component/CustomNoDataFound';
import AddressInfo from './AddressInfo';
import useModalUtils from 'utils/componentUtils/modalUtils';
import AddressForm from './AddressForm';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import CustomTwoInlineColumns from 'ui-component/CustomTwoInlineColumns/CustomTwoInlineColumns';
import { ArrowBack } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

const module = CONST_MODULE_ADDRESSES;
const idName = 'addressId';
const Addresses = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const querySource = queryParams.get('source');
  const {
    crudMethods,
    addressesState: { page, size, addressesData, addressesOne, getAllFetching, deleting },
  } = useAppContext();
  const { handleToggleModal } = useModalUtils();
  const { getMethodByModule } = useStoreAccessByModule();
  const [, setToggleAddress] = useState(false);
  const [deletingAddressId, setDeleting] = useState(null);

  const toggleModal = () => {
    handleToggleModal({ module });
  };

  const commonParams = useMemo(
    () => ({
      crudMethods,
      setState: getMethodByModule({ module }),
      module,
      page,
      size,
    }),
    [crudMethods, getMethodByModule, page, size]
  );

  useEffect(() => {
    handleApiAction({
      ...commonParams,
      action: CONST_GETALL,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchAll = () => handleApiAction({ ...commonParams, action: CONST_GETALL });

  const get = getId => {
    handleApiAction({
      ...commonParams,
      action: CONST_GET,
      getId,
      idName,
    });
  };

  const postOrPut = payload => {
    const user = getLocalUser();
    return handleApiAction({
      ...commonParams,
      action: payload?.[idName] ? CONST_PUT : CONST_POST,
      payload: {
        ...payload,
        userName: user?.userId,
      },
      idName,
      [idName]: payload?.[idName],
      refetchAll,
      // notification: { show: true, type: CONST_TYPE_SUCCESS },
      toggleModal,
    });
  };

  const deleteItem = delId => {
    return handleApiAction({
      ...commonParams,
      action: CONST_DELETE,
      idName,
      delId,
      refetchAll,
      // notification: { show: true, type: CONST_TYPE_SUCCESS },
    });
  };

  return (
    <>
      <CustomTwoInlineColumns>
        {querySource === 'checkout' ? (
          <CustomButton
            name="Go back"
            action={CONST_ACTION_ADD}
            module={module}
            handleClick={() => {
              navigate(-1);
            }}
            sx={{ mb: 2 }}
            startIcon={<ArrowBack />}
            variant="outlined"
          />
        ) : (
          <></>
        )}
        <CustomRightButton
          action={CONST_ACTION_ADD}
          module={module}
          handleClick={() => {
            toggleModal();
          }}
        />
      </CustomTwoInlineColumns>
      <Box>
        {addressesData?.length > 0 ? (
          addressesData.map((addressInfo, index) => {
            return (
              <AddressInfo
                key={addressInfo?.[idName] || index}
                addressInfo={addressInfo}
                isList={true}
                idName={idName}
                deleteItem={deleteItem}
                setToggleAddress={setToggleAddress}
                deleting={deleting}
                deletingAddressId={deletingAddressId}
                setDeleting={setDeleting}
              />
            );
          })
        ) : (
          <CustomNoDataFound itemsName="Address" loading={getAllFetching} />
        )}
      </Box>
      <AddressForm idName={idName} postOrPut={postOrPut} />
    </>
  );
};

export default Addresses;
