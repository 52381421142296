const API_AUTH = {
  signin: { api: '/auth/signin' },
  signup: { api: '/auth/signup' },
};

const API_CATEGORIES = {
  getAll: { api: '/all-product-category' },
  post: { api: '/product-category' },
  get: {
    api: '/product-category',
    ids: ['productCatId'],
  },
  put: { api: '/product-category', ids: ['productCatId'] },
  delete: { api: '/product-category', ids: ['productCatId'] },
  fetchCategoriesWithCount: { api: '/all-menu-category-with-count', ids: ['orgId'] },
};

const API_DISCOUNTS = {
  getAll: { api: '/all-discount', ids: ['orgId'] },
  post: { api: '/discount', ids: ['orgId'] },
  get: {
    api: '/discount',
    ids: ['discountId'],
  },
  put: { api: '/discount', ids: ['orgId', 'discountId'] },
  delete: { api: '/discount', ids: ['discountId'] },
};

const API_PRODUCTS = {
  getAll: { api: '/all-product-by-page', ids: ['orgId'] },
  post: { api: '/product', ids: ['orgId'] },
  get: { api: '/product', ids: ['productId'] },
  put: { api: '/product', ids: ['productId'] },
  delete: { api: '/product', ids: ['productId'] },
  updateProductAvailability: { api: '/menu-availability', ids: ['orgId', 'productId', 'available'] },
  searchProducts: { api: '/search-product-by-name-type-sku-price', ids: ['searchText'] },
  fetchCategoryProducts: { api: '/menu-by-category-id', ids: ['orgId', 'categoryId'] },
};

const API_CARTS = {
  getAll: { api: '/all-cart-item', ids: ['orgId', 'userName'] },
  post: { api: '/cart-item', ids: ['orgId', 'userName'] },
  get: { api: '/cart-item', ids: ['cartItemId'] },
  put: { api: '/cart-item', ids: ['cartItemId'] },
  delete: { api: '/cart-item', ids: ['cartItemId'] },
  deleteAll: { api: '/all-cart-item' },
};

const API_ORDERS = {
  getAll: { api: '/all-online-delivery-order-item', ids: ['orgId', 'userName'] },
  post: { api: '/online-delivery-order-item', ids: ['orgId', 'userName'] },
  postTakeAway: { api: '/take-away-order-item', ids: ['orgId'] },
  get: { api: '/order-item', ids: ['orderItemId'] },
  delete: { api: '/order-item', ids: ['orderItemId'] },
  getInternalOrders: { api: '/internal-order-session', ids: ['orgId', 'orderType'] },
  getCompletedOrders: { api: '/completed-order-session', ids: ['orgId', 'orderType'] },
  getAllOrdersForClient: {
    api: '/all-online-delivery-order-session',
    ids: ['userName'],
  },
  getOrderItemsBySession: { api: '/all-order-item', ids: ['orgId', 'orderSessionId'] },
  updateOrderStatus: { api: '/track-all-order-item', ids: ['orderSessionId', 'trackStatus'] },
  getTracks: { api: '/track-online-delivery-order-item', ids: ['orderItemId'] },
  getOrderBillReport: { api: '/report/customer-bill-pdf', ids: ['orgId', 'orderSessionId'] },
  cancelOrderByCustomer: { api: '/online-delivery-customer-cancel', ids: ['orderSessionId', 'userName'] },
  acceptOrRejectOrderByAdmin: { api: '/online-delivery-admin-order-accept-reject', ids: ['orgId', 'orderSessionId'] },
  getFailedOrdersByAdmin: { api: '/all-failed-online-delivery-order-session', ids: ['orgId'] },
  getCancellationReasons: { api: '/cancellation-reasons' },
  // Rider - Common
  getAllAwaitingRideOrders: { api: '/all-awaiting-ride-order', ids: ['orgId'] },
  // Rider - Admin
  getAllRiders: { api: '/all-riders-by-status', ids: ['orgId', 'riderStatus'] },
  assignRiderByAdmin: { api: '/admin-assign-rider', ids: ['rideOrderId', 'riderUserName'], method: 'post' },
  getAllAssignedRiderOrders: { api: '/all-assigned-ride-order', ids: ['orgId'] },
  getAllCompletedRiderOrders: { api: '/all-completed-rider-order', ids: ['orgId'] },
  // Rider - Delivery person
  assignOrderByRider: { api: '/assign-and-pick-ride-order', ids: ['rideOrderId', 'userName'], method: 'post' },
  getAllAssignedRiderOrdersForRider: { api: '/all-assigned-ride-order-by-user', ids: ['userName'] },
  getAllCompletedRiderOrdersForRider: { api: '/all-completed-rider-order-by-user', ids: ['userName'] },
  // Track order
  getRiderDetailsBySessionId: {
    api: '/rider-details',
    ids: ['orderSessionId'],
  },
  //** Order fee */
  // Delivery fee
  getAllDeliveryFees: { api: '/all-delivery-fees', ids: ['orgId'] },
  filterDeliveryFees: { api: '/delivery-fees', ids: ['flat', 'flong', 'tlat', 'tlong'] },
  addDeliveryFees: { api: '/delivery-fees', ids: ['orgId'] },
  getDeliveryFees: { api: '/delivery-fees', ids: ['deliveryFeeId'] },
  updateDeliveryFees: { api: '/delivery-fees', ids: ['orgId', 'deliveryFeeId'] },
  deleteDeliveryFees: { api: '/delivery-fees', ids: ['deliveryFeeId'] },
  // Small order fee
  getAllSmallOrderFees: { api: '/all-small-order-fees', ids: ['orgId'] },
  filterSmallOrderFees: { api: '/filter-small-order-fees', ids: ['maxOrderAmount'] },
  addSmallOrderFees: { api: '/small-order-fees', ids: ['orgId'] },
  getSmallOrderFees: { api: '/small-order-fees', ids: ['smallOrderFeeId'] },
  updateSmallOrderFees: { api: '/small-order-fees', ids: ['orgId', 'smallOrderFeeId'] },
  deleteSmallOrderFees: { api: '/small-order-fees', ids: ['smallOrderFeeId'] },
  // Service fee
  getAllServiceFees: { api: '/all-service-fees', ids: ['orgId'] },
  filterServiceFees: { api: '/filter-service-fees', ids: ['maxOrderAmount'] },
  addServiceFees: { api: '/service-fees', ids: ['orgId'] },
  getServiceFees: { api: '/service-fees', ids: ['serviceFeeId'] },
  updateServiceFees: { api: '/service-fees', ids: ['orgId', 'serviceFeeId'] },
  deleteServiceFees: { api: '/service-fees', ids: ['serviceFeeId'] },
};

const API_EMPLOYEES = {
  getAll: { api: '/all-employee-by-page', ids: ['orgId'] },
  post: { api: '/employee' },
  get: { api: '/employee', ids: ['employeeId'] },
  put: { api: '/employee', ids: ['employeeId'] },
  delete: { api: '/employee', ids: ['employeeId'] },
};

const API_DASHBOARDS = {
  getMonthlyOrderCounts: { api: '/dashboard/monthly-orders-counts', ids: ['orgId'] },
  getMostRatedProduct: { api: '/dashboard/most-rated-product', ids: ['orgId'] },
  getTodayOrderCounts: { api: '/dashboard/today-orders-counts', ids: ['orgId', 'selectedDate'] },
  getTopSellingProduct: { api: '/dashboard/top-selling-product', ids: ['orgId'] },
};

const API_BRANCHES = {
  getAll: { api: '/all-branch' },
  get: { api: '/branch', ids: ['orgId'] },
  post: { api: '/branch' },
  put: { api: '/branch', ids: ['orgId'] },
  updateBranchTimings: { api: '/schedule-branch-time' },
  getBranchTimings: { api: '/schedule-branch-time', ids: ['orgId'] },
  updateBranchAvailability: { api: '/branch-availability', ids: ['orgId', 'available'] },
  deleteBranchTimings: { api: '/schedule-branch-time', ids: ['timeSlotId'] },
};

const API_ADDRESSES = {
  getAll: { api: '/all-user-address', ids: ['userName'] },
  post: { api: '/user-address' },
  get: { api: '/user-address', ids: ['addressId'] },
  put: { api: '/user-address', ids: ['addressId'] },
  delete: { api: '/user-address', ids: ['addressId'] },
  getPrimaryAddress: { api: '/primary-address', ids: ['userName'] },
  updatePrimaryAddress: { api: '/set-primary-address', ids: ['userName'] },
};

const API_PAYMENTS = {
  createOrderPayment: { api: '/payment-detail', ids: ['orgId'] },
};

const API_VOUCHERS = {
  getAll: { api: '/all-voucher' },
  get: { api: '/voucher', ids: ['voucherCode'] },
  put: { api: '/voucher', ids: ['voucherCode'] },
  delete: { api: '/voucher', ids: ['voucherCode'] },
  createVoucherForAll: { api: '/voucher/all-customer' },
  createVoucherForUser: { api: '/voucher/specific-customer', ids: ['userName'] },
  applyVoucher: { api: '/voucher/apply-code', ids: ['voucherCode', 'userName'] },
};

const API_COMMON = {
  userAccountDelete: { api: '/delete-user-account' },
};

const moduleApis = {
  API_AUTH,
  API_CATEGORIES,
  API_DISCOUNTS,
  API_PRODUCTS,
  API_EMPLOYEES,
  API_CARTS,
  API_ORDERS,
  API_DASHBOARDS,
  API_BRANCHES,
  API_ADDRESSES,
  API_PAYMENTS,
  API_VOUCHERS,
  API_COMMON,
};

export default moduleApis;

export {
  API_AUTH,
  API_CATEGORIES,
  API_DISCOUNTS,
  API_PRODUCTS,
  API_EMPLOYEES,
  API_CARTS,
  API_ORDERS,
  API_DASHBOARDS,
  API_BRANCHES,
  API_ADDRESSES,
  API_PAYMENTS,
  API_COMMON,
};
