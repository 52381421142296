import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import OrderFeesList from './OrderFeesList';
import CustomTabs from 'ui-component/CustomTabs/CustomTabs';
import FeeForm from './FeeForm';
import { handleApiAction } from 'utils/apiUtils/apiAction';
import useAppContext from 'store/useAppContext';
import { CONST_GETALL, CONST_MODULE_ORDERS, CONST_POST, CONST_PUT, CONST_TYPE_SUCCESS } from 'utils/constants';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';

const getFeeConfig = type => {
  switch (type) {
    case 'order':
      return {
        title: 'Small Order Fee',
        fields: [
          { name: 'feeName', label: 'Order Fee Name', type: 'string' },
          { name: 'maxOrderAmount', label: 'Max Amount', type: 'number' },
          { name: 'flatAmount', label: 'Fee', type: 'number' },
          { name: 'percentage', label: 'Percentage', type: 'sting' },
          { name: 'description', label: 'Description', type: 'sting' },
        ],
        tableHeaders: ['Fee Name', 'Max Order Amount', 'Fee', 'Percentage', 'Description', 'Actions'],
        tableKeys: ['feeName', 'maxOrderAmount', 'flatAmount', 'percentage', 'description'],
      };
    case 'service':
      return {
        title: 'Service Fee',
        fields: [
          { name: 'serviceName', label: 'Service Fee Name', type: 'string' },
          { name: 'maxOrderAmount', label: 'Max Amount', type: 'number' },
          { name: 'flatAmount', label: 'Fee', type: 'number' },
          { name: 'percentage', label: 'Percentage', type: 'sting' },
          { name: 'description', label: 'Description', type: 'sting' },
        ],
        tableHeaders: ['Fee Name', 'Max Order Amount', 'Fee', 'Percentage', 'Description', 'Actions'],
        tableKeys: ['serviceName', 'maxOrderAmount', 'flatAmount', 'percentage', 'description'],
      };
    case 'delivery':
      return {
        title: 'Delivery Fee',
        fields: [
          { name: 'feeName', label: 'Delivery Fee Name', type: 'string' },
          { name: 'maxDistanceKm', label: 'Max Distance (km)', type: 'number' },
          { name: 'flatAmount', label: 'Fee', type: 'number' },
          { name: 'description', label: 'Description', type: 'sting' },
        ],
        tableHeaders: ['Fee Name', 'Max Distance', 'Fee', 'Description', 'Actions'],
        tableKeys: ['feeName', 'maxDistanceKm', 'flatAmount', 'description'],
      };
    default:
      return {};
  }
};

const module = CONST_MODULE_ORDERS;
const OrderFeeManagement = () => {
  const {
    crudMethods,
    ordersState: { feeActiveTab, feeListFetching, feeInfoSaving }
  } = useAppContext();
  const { getMethodByModule } = useStoreAccessByModule();
  const setState = getMethodByModule({ module });
  const [isSaveFormVisible, setSaveFormVisible] = useState(false);

  const toggleSaveFormVisible = () => setSaveFormVisible(prev => !prev);

  const handleLoadingChange = (loaderName, isLoading) => {
    setContextState({
      setState,
      paramName: loaderName,
      paramValue: isLoading
    })
  } 

  const commonParams = useMemo(
    () => ({
      crudMethods,
      setState,
      module
    }),
    [crudMethods, setState]
  );

  const handleFetchFeeList = async () => {
    let apiPropName = ''
    let stateParam = ''
    if(feeActiveTab === 'ORDER_FEE') {
      apiPropName = 'getAllSmallOrderFees'
      stateParam = 'orderFeeData'
    } else if(feeActiveTab === 'SERVICE_FEE') {
      apiPropName = 'getAllServiceFees'
      stateParam = 'serviceFeeData'
    } else if(feeActiveTab === 'DELIVERY_FEE') {
      apiPropName = 'getAllDeliveryFees'
      stateParam = 'deliveryFeeData'
    }
    handleLoadingChange('feeListFetching', true);
    await handleApiAction({
      ...commonParams,
      apiPropName,
      action: CONST_GETALL,
      stateParam,
    });
    handleLoadingChange('feeListFetching', false);
  }

  useEffect(() => {
    if(feeActiveTab) {
      handleFetchFeeList()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feeActiveTab])

  const postOrPut = async (payload) => {
    let idName = '';
    let apiPropName = ''
    if(feeActiveTab === 'ORDER_FEE') {
      apiPropName = 'addSmallOrderFees'
      if(payload?.['smallOrderFeeId']) {
        idName = 'smallOrderFeeId'
        apiPropName = 'updateSmallOrderFees'
      }
    } else if(feeActiveTab === 'SERVICE_FEE') {
      apiPropName = 'addServiceFees'
      if(payload?.['serviceFeeId']) {
        idName = 'serviceFeeId'
        apiPropName = 'updateServiceFees'
      }
    } else if(feeActiveTab === 'DELIVERY_FEE') {
      apiPropName = 'addDeliveryFees'
      if(payload?.['deliveryFeeId']) {
        idName = 'deliveryFeeId'
        apiPropName = 'updateDeliveryFees'
      }
    }
    handleLoadingChange('feeInfoSaving', true);
    await handleApiAction({
      ...commonParams,
      apiPropName,
      action: payload?.[idName] ? CONST_PUT : CONST_POST,
      payload,
      idName,
      notification: { show: true, type: CONST_TYPE_SUCCESS },
    });
    handleFetchFeeList();
    toggleSaveFormVisible();
    handleLoadingChange('feeInfoSaving', false);
  };

  return (
    <Box sx={{ my: 1 }}>
      <CustomTabs
        module={module}
        tabName="feeActiveTab"
        tabsList={[
          {
            label: 'Small Order Fee',
            value: 'ORDER_FEE',
            element: (
              <Box>
                <OrderFeesList
                  config={getFeeConfig('order')}
                  isSaveFormVisible={isSaveFormVisible}
                  toggleSaveFormVisible={toggleSaveFormVisible}
                  handleFetchFeeList={handleFetchFeeList}>
                  {isSaveFormVisible && (
                    <FeeForm
                      config={getFeeConfig('order')}
                      onSubmit={payload => {
                        postOrPut(payload)
                      }}
                      onCancel={toggleSaveFormVisible}
                      feeInfoSaving={feeInfoSaving}
                    />
                  )}
                </OrderFeesList>
              </Box>
            ),
          },
          {
            label: 'Service Fee',
            value: 'SERVICE_FEE',
            element: (
              <Box>
                <OrderFeesList
                  config={getFeeConfig('service')}
                  isSaveFormVisible={isSaveFormVisible}
                  toggleSaveFormVisible={toggleSaveFormVisible}
                   handleFetchFeeList={handleFetchFeeList}>
                  {isSaveFormVisible && (
                    <FeeForm
                      config={getFeeConfig('service')}
                      onSubmit={payload => {
                        postOrPut(payload)
                      }}
                      onCancel={toggleSaveFormVisible}
                      feeInfoSaving={feeInfoSaving}
                    />
                  )}
                </OrderFeesList>
              </Box>
            ),
          },
          {
            label: 'Delivery Fee',
            value: 'DELIVERY_FEE',
            element: (
              <Box>
                <OrderFeesList
                  config={getFeeConfig('delivery')}
                  isSaveFormVisible={isSaveFormVisible}
                  toggleSaveFormVisible={toggleSaveFormVisible}
                   handleFetchFeeList={handleFetchFeeList}>
                  {isSaveFormVisible && (
                    <FeeForm
                      config={getFeeConfig('delivery')}
                      onSubmit={payload => {
                        postOrPut(payload)
                      }}
                      onCancel={toggleSaveFormVisible}
                      feeInfoSaving={feeInfoSaving}
                    />
                  )}
                </OrderFeesList>
              </Box>
            ),
          },
        ]}
        isActionPending={feeListFetching || feeInfoSaving}
      />
    </Box>
  );
};

export default OrderFeeManagement;
